<script setup>
// layout
import Layout from "../../layouts/main.vue";
import toastr from "toastr";
import mushroom from "cem-primary-api";
import MethodService from "../../service/MethodService";
import Data from "./dataFormTimeRange";
import { FormInstance } from "element-plus";
import Validate from "../../service/ValidService";
import { useRouter, useRoute } from "vue-router";
import { onMounted, reactive, ref, watch } from "vue";
import { ListService } from "@/stores/ListService";
const storeListService = ListService();
import { ListTableScreen } from "@/stores/ListTableScreen";
const storeListTableScreen = ListTableScreen();
import { ListUsecase } from "@/stores/ListUsecase";
const storeListUsecase = ListUsecase();
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { langWeb } from "@/stores/lang";
import LanguageService from "../../service/LanguageService";
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());

const router = useRouter();
const route = useRoute();
const loadingBtn = ref(false);
const validateFlag = ref(false);
const targetTypeSelect = Data.targetType;
const listDichVu = ref([]);
const listTableScreen = ref([]);
const listUsecase = ref([]);
const listDynamicDashboard = ref([]);
const dataForm = reactive({
  value: MethodService.copyObject(Data.dataForm),
});
const ruleFormRef = ref(FormInstance);

const rulesForm = reactive({
  target_type: [Validate.requiredChange],
  target_id: [Validate.required],
  day_limit: [Validate.requiredInputNumber],
  link: [Validate.checkNoSpace, Validate.required],
});

const getTimeRange = async () => {
  try {
    const response = await mushroom.time_range.findByIdAsync({
      id: route.params.id,
    });
    dataForm.value = response.result;
  } catch (e) {
    console.error("Có lỗi: %o", e);
    MethodService.showError(e.code);
  }
};
const getListDynamicDashboard = async () => {
  try {
    const response = await mushroom.dynamic_dashboard.getAllAsync();
    if (response.result) {
      response.result.forEach(async (item) => {
        const response = await mushroom.menu.findByIdAsync({
          id: item.menu_id,
        });
        listDynamicDashboard.value.push({
          value: response.result.target_id,
          label: response.result.label,
        });
      });
      console.log("listDynamicDashboard", listDynamicDashboard.value);
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const addTimeRange = async (formEl) => {
  if (!formEl) return;
  formEl.validate(async (valid) => {
    loadingBtn.value = true;
    validateFlag.value = true;
    if (valid) {
      const time_range_object = {
        target_type: dataForm.value.target_type,
        target_id: dataForm.value.target_id,
        link:
          dataForm.value.target_type === "static" ? dataForm.value.link : null,
        day_limit: dataForm.value.day_limit,
      };
      try {
        const newId = await mushroom.time_range.createAsync(time_range_object);
        if (newId) {
          toastr.success(t("toastr_add_success"));
          router.go(-1);
        }
      } catch (error) {
        if (error.meta.field === "$unique_key") {
          toastr.error(t("toastr_had_name"));
        } else MethodService.showError(error.code);
      } finally {
        loadingBtn.value = false;
      }
    } else {
      loadingBtn.value = false;
      return false;
    }
  });
};
const updateTimeRange = async (formEl) => {
  if (!formEl) return;
  formEl.validate(async (valid) => {
    loadingBtn.value = true;
    validateFlag.value = true;
    if (valid) {
      const time_range_object = {
        id: route.params.id,
        target_type: dataForm.value.target_type,
        target_id: dataForm.value.target_id,
        link:
          dataForm.value.target_type === "static" ? dataForm.value.link : null,
        day_limit: dataForm.value.day_limit,
      };
      try {
        const newId = await mushroom.time_range.partialUpdateAsync(
          time_range_object
        );
        if (newId) {
          toastr.success(t("toastr_update_success"));
          router.go(-1);
        }
      } catch (error) {
        if (error.meta.field === "$unique_key") {
          toastr.error(t("toastr_had_name"));
        } else MethodService.showError(error.code);
      } finally {
        loadingBtn.value = false;
      }
    } else {
      loadingBtn.value = false;
      return false;
    }
  });
};

watch(
  () => lang_web.showLang,
  () => {
    lang.value = lang_web.showLang.toLocaleUpperCase();
    LanguageService.lang = LanguageService[lang.value];
    if (validateFlag.value === true) {
      rulesForm.value = MethodService.copyObject(rulesForm)
    }
  }
);

onMounted(async () => {
  await storeListService.getAllListService();
  await storeListTableScreen.getAllListTableScreen();
  await storeListUsecase.getAllListUsecase();
  await getListDynamicDashboard();
  storeListService.data.forEach((item) => {
    listDichVu.value.push({
      value: item.id,
      label: item.name,
    });
  });
  storeListTableScreen.data.forEach((item) => {
    listTableScreen.value.push({
      value: item.id,
      label: item.name,
    });
  });
  storeListUsecase.data.forEach((item) => {
    listUsecase.value.push({
      value: item.id,
      label: item.name,
    });
  });

  if (route.name === "TimeRangeUpdate") {
    await getTimeRange();
  }
});
</script>

<template>
  <Layout>
    <div class="card">
      <div class="card-header">
        <h3 v-if="route.name === 'TimeRangeAdd'">
          {{ $t("t_add_time_range") }}
        </h3>
        <h3 v-if="route.name === 'TimeRangeDetail'">
          {{ $t("t_detail_time_range") }}
        </h3>
        <h3 v-if="route.name === 'TimeRangeUpdate'">
          {{ $t("t_update_time_range") }}
        </h3>
      </div>
      <div class="card-body">
        <div class="main-body">
          <el-form
            ref="ruleFormRef"
            :model="dataForm.value"
            :rules="rulesForm"
            label-width="200px"
            class="form-user"
          >
            <el-form-item :label="$t('t-target-type')" prop="target_type">
              <el-select
                v-model="dataForm.value.target_type"
                filterable
                :placeholder="$t('t-placeholder-target-type')"
                style="width: 100%"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
                @change="dataForm.value.target_id = undefined"
                :disabled="route.name === 'TableScreenDetail'"
              >
                <el-option
                  v-for="item in targetTypeSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item
              :label="$t('t-link')"
              prop="link"
              v-if="
                dataForm.value.target_type === 'static' ||
                dataForm.value.target_type === undefined
              "
            >
              <el-input
                v-model="dataForm.value.link"
                :placeholder="$t('t-place-service-health-kpi-link')"
                :disabled="route.name === 'TableScreenDetail'"
              ></el-input>
            </el-form-item>

            <el-form-item :label="$t('t-target')" prop="target_id" v-else>
              <el-select
                filterable
                v-if="dataForm.value.target_type === 'service'"
                v-model="dataForm.value.target_id"
                :placeholder="$t('t-select-service')"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
                :disabled="route.name === 'TableScreenDetail'"
              >
                <el-option
                  v-for="item in listDichVu"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <el-select
                filterable
                v-if="dataForm.value.target_type === 'table_screen'"
                v-model="dataForm.value.target_id"
                :placeholder="$t('t-select-table-screen')"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
              >
                <el-option
                  v-for="item in listTableScreen"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <el-select
                filterable
                v-if="dataForm.value.target_type === 'usecase'"
                v-model="dataForm.value.target_id"
                :placeholder="$t('t-select-usecase')"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
              >
                <el-option
                  v-for="item in listUsecase"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <el-select
                filterable
                v-if="dataForm.value.target_type === 'dynamic'"
                v-model="dataForm.value.target_id"
                placeholder="Chọn menu dynamic"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
              >
                <el-option
                  v-for="item in listDynamicDashboard"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('t_day_limit')" prop="day_limit">
              <el-input-number
                v-model="dataForm.value.day_limit"
                controls-position="right"
                :min="1"
                :step="1"
                :step-strictly="true"
                :placeholder="$t('t-place-time-range')"
                style="width: 280px"
              >
              </el-input-number>
            </el-form-item>

            <div class="text-center">
              <button
                type="button"
                class="btn btn-soft-secondary btn-border mx-3"
                @click="router.go(-1)"
              >
                {{ $t("t-back") }}
              </button>
              <el-button
                type="button"
                style="height: 40px"
                class="btn btn-secondary btn-border"
                @click="addTimeRange(ruleFormRef)"
                v-if="route.name === 'TimeRangeAdd'"
                :loading="loadingBtn"
              >
                {{ $t("t-add") }}
              </el-button>
              <el-button
                type="button"
                style="height: 40px"
                class="btn btn-secondary btn-border"
                @click="updateTimeRange(ruleFormRef)"
                v-if="route.name === 'TimeRangeUpdate'"
                :loading="loadingBtn"
              >
                {{ $t("t-update") }}
              </el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
